// Dependencies
import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
// Hooks & Helpers
// ...
// Wrappers
import Layout from "../wrappers/Layout"
// Components
import Moodboard from "../components/Moodboard"

const ChapterTemplate = ({ data }) => {

	const { title, chapterNumber, summary, shareImage, backgroundColor } = data.contentfulChapter

  return (
    <Layout 
			title={`${chapterNumber} ${title}`} 
			description={summary?.text} 
			shareImage={shareImage?.file?.url} 
			hasNotifications 
			backgroundColor={backgroundColor}
		>
			<section className="guttersx2">
				<Moodboard {...data.contentfulChapter} showCaptions={true} />
			</section>
    </Layout>
  )
}

ChapterTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default ChapterTemplate

export const ChapterTemplatePageQuery = graphql`
  query ChapterTemplateQuery($slug: String!) {
    contentfulChapter(slug: {eq: $slug}) {
      ...ContentfulChapterFragment
    }
  }
`
